import {
  ArrowLongRightIcon,
  ArrowTurnDownRightIcon,
  ArrowTurnUpRightIcon,
  ClipboardDocumentListIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import Button from "../common/Button";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import "react-h5-audio-player/lib/styles.css";
import Tooltip from "../common/Tooltip";
import Input from "../common/Input";

interface SnippetsProps {
  onInsertContent?: (content: string, mode?: string) => void;
}

interface Snippet {
  id: string;
  title: string;
  content: string;
  description?: string;
}

function Snippets({ onInsertContent }: SnippetsProps) {
  const [userSnippets, setUserSnippets] = useState<Snippet[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newSnippet, setNewSnippet] = useState({
    title: "",
    content: "",
    description: "",
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingSnippet, setEditingSnippet] = useState<Snippet | null>(null);

  useEffect(() => {
    fetchSnippets();
  }, []);

  const formatSnippet = (snippet: string) => {
    return snippet
      .replace(/\n/g, "</p><p>")
      .replace(/<p><\/p>/g, "")
      .trim();
  };

  const fetchSnippets = async () => {
    try {
      const response = await axios.get("/api/snippets", {
        withCredentials: true,
      });
      setUserSnippets(response.data.snippets);
    } catch (error) {
      toast.error("Failed to fetch snippets");
    }
  };

  const handleAddSnippet = async () => {
    try {
      const response = await axios.post("/api/snippets", newSnippet, {
        withCredentials: true,
      });
      setUserSnippets([...userSnippets, response.data.snippet]);
      setIsDialogOpen(false);
      setNewSnippet({ title: "", content: "", description: "" });
      toast.success("Snippet added successfully");
    } catch (error) {
      toast.error("Failed to add snippet");
    }
  };

  const handleDeleteSnippet = async (id: string) => {
    try {
      await axios.delete(`/api/snippets/${id}`, {
        withCredentials: true,
      });
      setUserSnippets(userSnippets.filter((snippet) => snippet.id !== id));
      toast.success("Snippet deleted successfully");
    } catch (error) {
      toast.error("Failed to delete snippet");
    }
  };

  const handleEditSnippet = async (id: string) => {
    const snippet = userSnippets.find((s) => s.id === id);
    if (snippet) {
      setEditingSnippet(snippet);
      setNewSnippet({
        title: snippet.title,
        content: snippet.content,
        description: snippet.description || "",
      });
      setIsDialogOpen(true);
      setIsEditMode(true);
    }
  };

  const handleSaveEdit = async () => {
    if (!editingSnippet) return;

    try {
      const response = await axios.patch(
        `/api/snippets/${editingSnippet.id}`,
        newSnippet,
        { withCredentials: true }
      );

      setUserSnippets(
        userSnippets.map((snippet) =>
          snippet.id === editingSnippet.id ? response.data.snippet : snippet
        )
      );

      setIsDialogOpen(false);
      setIsEditMode(false);
      setEditingSnippet(null);
      setNewSnippet({ title: "", content: "", description: "" });
      toast.success("Snippet updated successfully");
    } catch (error) {
      toast.error("Failed to update snippet");
    }
  };

  const handleInsertContent = (content: string, mode?: string) => {
    if (onInsertContent) {
      onInsertContent(formatSnippet(content), mode);
      toast.success("Added to report");
    }
  };

  return (
    <>
      <div className="overflow-y-scroll md:overflow-visible max-h-[70vh] md:max-h-fit mb-4 md:mb-0 pt-2 md:pt-0">
        <div className="flex items-center prose mb-2">
          <ClipboardDocumentListIcon className="size-5 text-blue-500" />
          <h3 className="m-0 ml-2 text-base">Snippets</h3>
        </div>
        <div className="grid gap-y-2">
          {userSnippets.length > 0 ? (
            userSnippets.map((snippet) => (
              <Tooltip
                key={snippet.id}
                placement="top"
                overlay={snippet.description || ""}
              >
                <div className="dropdown dropdown-end">
                  <div
                    tabIndex={0}
                    className="p-4 text-sm cursor-pointer transition duration-100 border-b-2 bg-gray-50 rounded-lg hover:bg-blue-50 border-transparent"
                  >
                    <h4 className="font-semibold">{snippet.title}</h4>
                    <p className="text-black-300 line-clamp-2">
                      {snippet.content}
                    </p>
                  </div>
                  <ul
                    tabIndex={0}
                    className="dropdown-content menu bg-base-100 rounded-lg z-20 p-2 shadow mt-2 w-full"
                  >
                    <li>
                      <p
                        className="hover:bg-gray-100"
                        onClick={() =>
                          handleInsertContent(snippet.content, "cursor")
                        }
                      >
                        <ArrowLongRightIcon className="size-4" />
                        Add to cursor
                      </p>
                    </li>
                    <li>
                      <p
                        className="hover:bg-gray-100"
                        onClick={() =>
                          handleInsertContent(snippet.content, "top")
                        }
                      >
                        <ArrowTurnUpRightIcon className="size-4" />
                        Add to top of report
                      </p>
                    </li>
                    <li>
                      <p
                        className="hover:bg-gray-100"
                        onClick={() =>
                          handleInsertContent(snippet.content, "end")
                        }
                      >
                        <ArrowTurnDownRightIcon className="size-4" />
                        Add to end of report
                      </p>
                    </li>
                    <li>
                      <p
                        className="hover:bg-gray-100"
                        onClick={() => handleEditSnippet(snippet.id)}
                      >
                        <PencilSquareIcon className="size-4" />
                        Edit snippet
                      </p>
                    </li>
                    <li>
                      <p
                        className="hover:bg-gray-100"
                        onClick={() => handleDeleteSnippet(snippet.id)}
                      >
                        <TrashIcon className="size-4" />
                        Delete snippet
                      </p>
                    </li>
                  </ul>
                </div>
              </Tooltip>
            ))
          ) : (
            <p className="text-sm my-2">
              Create reusable text blocks to speed up your reporting.
            </p>
          )}

          <Button
            variant="primary"
            className="w-full"
            onClick={() => setIsDialogOpen(true)}
          >
            Add Snippet
          </Button>
        </div>
      </div>

      {/* Add Snippet Dialog */}
      {isDialogOpen && (
        <dialog className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg">
              {isEditMode ? "Edit Snippet" : "Add New Snippet"}
            </h3>
            <div className="form-control">
              <h3 className="font-semibold text-sm mt-2 mb-1">Title</h3>
              <Input
                type="text"
                className="input input-bordered !max-w-full mb-2"
                value={newSnippet.title}
                onChange={(e) =>
                  setNewSnippet({ ...newSnippet, title: e.target.value })
                }
              />
              <h3 className="font-semibold text-sm mt-2 mb-1">
                Description (optional)
              </h3>
              <Input
                type="text"
                className="input input-bordered !max-w-full mb-2"
                value={newSnippet.description}
                onChange={(e) =>
                  setNewSnippet({ ...newSnippet, description: e.target.value })
                }
              />
              <h3 className="font-semibold text-sm mt-2 mb-1">Content</h3>
              <textarea
                className="textarea textarea-bordered focus:outline-none focus:border-blue-500 border-grey-500 bg-grey-50 transition-colors !max-w-full"
                value={newSnippet.content}
                onChange={(e) =>
                  setNewSnippet({ ...newSnippet, content: e.target.value })
                }
              />
            </div>
            <div className="modal-action">
              <Button
                variant="clean"
                onClick={() => {
                  setIsDialogOpen(false);
                  setIsEditMode(false);
                  setEditingSnippet(null);
                  setNewSnippet({ title: "", content: "", description: "" });
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={isEditMode ? handleSaveEdit : handleAddSnippet}
              >
                {isEditMode ? "Save" : "Add"}
              </Button>
            </div>
          </div>
        </dialog>
      )}
    </>
  );
}

export default Snippets;
