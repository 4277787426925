import {
  ArrowUpOnSquareIcon,
  ChatBubbleBottomCenterTextIcon,
  MinusIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  BoldIcon,
  ChevronDownIcon,
  ItalicIcon,
  ListBulletIcon,
  NumberedListIcon,
  PaintBrushIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from "@heroicons/react/24/solid";
import { Editor as EditorProps } from "@tiptap/react";
import axios from "axios";
import cn from "classnames";
import moment from "moment";
import { ReactElement, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { SubscriptionStatusType } from "../../db/schema";
import { useIsMobile } from "../../hooks/useIsMobile";
import Button from "../common/Button";
import IconButton from "../common/IconButton";
import Tooltip from "../common/Tooltip";

interface EditorMenuBarProps {
  editor: EditorProps | null;
  disabled?: boolean;
  caseId: string | undefined;
  score: number | null | undefined;
}

const iconBtnStyles = "size-4";

const copySuccessToast = () => {
  toast.success("Copied to clipboard");
};

function convertHtmlToPlainText(html: string): string {
  return (
    html
      // Remove style attributes
      .replace(/\s*style="[^"]*"/g, "")

      // Remove data attributes
      .replace(/\s*data-[^=]*="[^"]*"/g, "")

      // Handle headings with double line break after
      .replace(/<h[1-6]>(.*?)<\/h[1-6]>/g, "$1\n\n")

      // Handle blockquotes with newlines
      .replace(/<blockquote><p>(.*?)<\/p><\/blockquote>/g, '\n"$1"\n\n')

      // Handle unordered lists
      .replace(/<ul>(\s*<li><p>.*?<\/p><\/li>\s*)*<\/ul>/g, (match) => {
        return (
          "\n" +
          match
            .replace(/<ul>|<\/ul>/g, "")
            .replace(/<li><p>(.*?)<\/p><\/li>/g, "- $1\n") +
          "\n"
        );
      })

      // Handle ordered lists with custom start attribute
      .replace(
        /<ol(?:\s+start="(\d+)")?>(\s*<li><p>.*?<\/p><\/li>\s*)*<\/ol>/g,
        (match, startNum) => {
          let number = parseInt(startNum || "1") - 1;
          return (
            "\n" +
            match
              .replace(/<ol(?:\s+start="\d+")?>|<\/ol>/g, "")
              .replace(/<li><p>(.*?)<\/p><\/li>/g, (_, content) => {
                number++;
                return `${number}. ${content}\n`;
              }) +
            "\n"
          );
        }
      )

      // Handle paragraphs with double line break
      .replace(/<p>(.*?)<\/p>/g, "$1\n\n")

      // Handle horizontal rules
      .replace(/<hr\s*\/?>/g, "\n---\n\n")

      // Handle line breaks
      .replace(/<br\s*\/?>/g, " ")

      // Remove strikethrough content completely
      .replace(/<s>.*?<\/s>/g, "")

      // Remove formatting tags but keep their content
      .replace(/<(mark|u|span|em|strong)[^>]*>(.*?)<\/\1>/g, "$2")

      // Remove any remaining HTML tags
      .replace(/<[^>]+>/g, "")

      // Clean up excessive newlines while preserving paragraph breaks
      .replace(/\n{3,}/g, "\n\n")

      // Trim extra whitespace at start/end
      .trim()
  );
}

const copyToClipboardFallback = (text: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.style.position = "fixed";
      textarea.style.opacity = "0";
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      resolve();
    } catch (err) {
      reject(err);
    }
  });
};

const EditorMenuBar = function ({
  editor,
  disabled,
  caseId,
  score,
}: EditorMenuBarProps) {
  const [isTextColorPickerOpen, setIsTextColorPickerOpen] = useState(false);
  const [isHighlightColorPickerOpen, setIsHighlightColorPickerOpen] =
    useState(false);
  const [isHeadingDropdownOpen, setIsHeadingDropdownOpen] = useState(false);
  const dialogRef = useRef<HTMLDialogElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const {
    user,
    subscriptionStatus,
    subscriptionCurrentPeriodEnd,
    refreshSubscriptionStatus,
  } = useAuth();
  const isMobile = useIsMobile();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsHeadingDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    refreshSubscriptionStatus();
  }, []); // Run once when component mounts

  const handleCopyToClipboard = async () => {
    if (editor) {
      try {
        const content = editor.getHTML();
        const plainText = convertHtmlToPlainText(content);

        await axios.post(
          "/api/audits",
          {
            caseId,
            content,
            score,
          },
          {
            withCredentials: true,
          }
        );

        try {
          await navigator.clipboard.writeText(plainText);
        } catch (err) {
          // Fallback for mobile devices
          await copyToClipboardFallback(plainText);
        }

        dialogRef.current?.close();
        copySuccessToast();
      } catch (error) {
        console.error("Error in copy process:", error);
        toast.error(
          error.response?.status === 401
            ? "Please log in to export content"
            : "Failed to export content"
        );
      }
    }
  };

  const getTimeRemaining = () => {
    if (
      !subscriptionCurrentPeriodEnd &&
      subscriptionStatus === SubscriptionStatusType.enum.trialing
    )
      return "";
    const now = moment();
    const end = moment(subscriptionCurrentPeriodEnd);
    const diff = end.diff(now, "hours");

    if (diff < 24) {
      return `${diff} hours left in trial`;
    }
    return `${Math.ceil(diff / 24)} days left in trial`;
  };

  type HeadingLevel = 1 | 2 | 3;
  interface HeadingOption {
    name: ReactElement;
    level: HeadingLevel | 0;
  }
  const headingLevels: HeadingOption[] = [
    { name: <p>Paragraph</p>, level: 0 },
    { name: <h1>Heading 1</h1>, level: 1 },
    { name: <h2>Heading 2</h2>, level: 2 },
    { name: <h3>Heading 3</h3>, level: 3 },
  ];

  if (editor == null) {
    return null;
  }

  const getCurrentHeading = () => {
    const heading = headingLevels.find((h) =>
      h.level === 0
        ? editor.isActive("paragraph")
        : editor.isActive("heading", { level: h.level })
    );
    return heading ? heading.name : "Paragraph";
  };

  const applyHeading = (level: HeadingOption["level"]) => {
    if (level === 0) {
      editor.chain().focus().setParagraph().run();
    } else {
      editor.chain().focus().toggleHeading({ level }).run();
    }
    setIsHeadingDropdownOpen(false);
  };

  return (
    <div className="sticky top-16 md:top-0 z-10 bg-gray-100 border-b-2">
      <div className="flex items-center justify-between w-full py-2 px-2">
        <div className="flex items-center space-x-1">
          <div className="relative inline-block" ref={dropdownRef}>
            <div>
              <IconButton
                type="button"
                id="heading-menu"
                aria-expanded="true"
                aria-haspopup="true"
                onClick={() => setIsHeadingDropdownOpen(!isHeadingDropdownOpen)}
                description="Styles"
                disabled={disabled}
              >
                <div className="flex items-center">
                  <p className="text-xs">{getCurrentHeading()}</p>
                  <ChevronDownIcon
                    className={cn(iconBtnStyles, "ml-2")}
                    aria-hidden="true"
                  />
                </div>
              </IconButton>
            </div>

            {isHeadingDropdownOpen && (
              <div
                className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="heading-menu"
              >
                <div className="py-1" role="none">
                  {headingLevels.map((heading) => (
                    <button
                      key={heading.level}
                      className={`block w-full text-left px-4 py-2 text-xs prose ${
                        heading.level === 0
                          ? editor.isActive("paragraph")
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700"
                          : editor.isActive("heading", { level: heading.level })
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-700"
                      } hover:bg-gray-100 hover:text-gray-900`}
                      role="menuitem"
                      onClick={() => applyHeading(heading.level)}
                    >
                      {heading.name}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <IconButton
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleBold().run()
            }
            isActive={editor.isActive("bold")}
            description="Bold"
          >
            <BoldIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleItalic().run()
            }
            isActive={editor.isActive("italic")}
            description="Italic"
          >
            <ItalicIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleUnderline().run()
            }
            isActive={editor.isActive("underline")}
            description="Underline"
          >
            <UnderlineIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleStrike().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleStrike().run()
            }
            isActive={editor.isActive("strike")}
            description="Strikethrough"
          >
            <StrikethroughIcon className={iconBtnStyles} />
          </IconButton>
          <Tooltip overlay="Text Color">
            <div className="relative inline-block">
              <IconButton isActive={isTextColorPickerOpen} disabled={disabled}>
                <PencilIcon className={iconBtnStyles} />
              </IconButton>
              <input
                type="color"
                onInput={(e) => {
                  editor
                    .chain()
                    .focus()
                    .setColor((e.target as HTMLInputElement).value)
                    .run();
                }}
                onFocus={() => setIsTextColorPickerOpen(true)}
                onBlur={() => setIsTextColorPickerOpen(false)}
                className="absolute inset-0 opacity-0 cursor-pointer"
              />
            </div>
          </Tooltip>
          <Tooltip overlay="Highlight color">
            <div className="relative inline-block">
              <IconButton
                isActive={isHighlightColorPickerOpen}
                disabled={disabled}
              >
                <PaintBrushIcon className={iconBtnStyles} />
              </IconButton>
              <input
                type="color"
                onInput={(e) => {
                  editor
                    .chain()
                    .focus()
                    .setHighlight({
                      color: (e.target as HTMLInputElement).value,
                    })
                    .run();
                }}
                onFocus={() => setIsHighlightColorPickerOpen(true)}
                onBlur={() => setIsHighlightColorPickerOpen(false)}
                className="absolute inset-0 opacity-0 cursor-pointer"
              />
            </div>
          </Tooltip>
          <IconButton
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleBlockquote().run()
            }
            isActive={editor.isActive("blockquote")}
            description="Blockquote"
          >
            <ChatBubbleBottomCenterTextIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().setHorizontalRule().run()}
            disabled={
              disabled ||
              !editor.can().chain().focus().setHorizontalRule().run()
            }
            description="Horizontal line"
          >
            <MinusIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleBulletList().run()
            }
            isActive={editor.isActive("bulletList")}
            description="Bullet list"
          >
            <ListBulletIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            disabled={
              disabled ||
              !editor.can().chain().focus().toggleOrderedList().run()
            }
            isActive={editor.isActive("orderedList")}
            description="Ordered list"
          >
            <NumberedListIcon className={iconBtnStyles} />
          </IconButton>
          {/* <IconButton
            onClick={() => editor.chain().focus().undo().run()}
            disabled={disabled || !editor.can().chain().focus().undo().run()}
            description="Undo"
          >
            <ArrowUturnLeftIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().redo().run()}
            disabled={disabled || !editor.can().chain().focus().redo().run()}
            description="Redo"
          >
            <ArrowUturnRightIcon className={iconBtnStyles} />
          </IconButton> */}
        </div>
        <div className="flex justify-end">
          {subscriptionStatus === SubscriptionStatusType.enum.trialing && (
            <div className="p-2 text-blue-500 underline">
              <Link to="/subscription">
                <>{getTimeRemaining()}</>
              </Link>
            </div>
          )}
          <Button
            className={cn(
              "fixed md:relative bottom-0 left-1/2 -translate-x-1/2 md:translate-x-0 md:left-auto md:bottom-auto",
              isMobile && "!w-full rounded-none"
            )}
            variant="primary"
            size="sm"
            disabled={disabled}
            onClick={() => dialogRef.current?.showModal()}
          >
            <div className="flex items-center">
              <ArrowUpOnSquareIcon className="size-6 mr-1" />
              <p>Export</p>
            </div>
          </Button>
        </div>
      </div>
      <dialog ref={dialogRef} className="modal">
        <div className="modal-box prose w-11/12 md:w-5/12 p-8">
          <h2>Review and acknowledge</h2>
          <p>
            I affirm that this report, generated with the assistance of Police
            Narratives Al, has been thoroughly reviewed and edited by me to
            ensure its accuracy in representing my recollection of the reported
            events. I acknowledge the potential legal implications of this
            statement and, if required, am prepared to testify to its accuracy.
          </p>
          <form method="dialog">
            <button className="btn absolute top-4 right-4 z-10 border-0 shadow-none">
              <XMarkIcon className="size-6" />
            </button>
            <Button
              onClick={handleCopyToClipboard}
              className="btn-primary w-full"
              variant="primary"
            >
              Agree and copy to clipboard
            </Button>
          </form>
        </div>
      </dialog>
    </div>
  );
};

export default EditorMenuBar;
