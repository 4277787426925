import React from "react";

interface LoadingProps {
  fullScreen?: boolean;
  className?: string;
}

const Loading: React.FC<LoadingProps> = ({
  fullScreen = true,
  className = "",
}) => {
  if (fullScreen) {
    return (
      <div className="fixed inset-0 bg-white/90 flex justify-center items-center z-50">
        <div className="w-12 h-12 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className={`flex justify-center items-center ${className}`}>
      <div className="w-8 h-8 border-3 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
    </div>
  );
};

export default Loading;
